import { render, staticRenderFns } from "./UiDatePicker.vue?vue&type=template&id=19df6bfc&scoped=true&"
import script from "./UiDatePicker.vue?vue&type=script&lang=js&"
export * from "./UiDatePicker.vue?vue&type=script&lang=js&"
import style0 from "./UiDatePicker.vue?vue&type=style&index=0&id=19df6bfc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19df6bfc",
  null
  
)

export default component.exports