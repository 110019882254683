<template>
  <v-date-picker
    mode="date"
    v-model="dateValue"
    :update-on-input="false"
    class="ui-date-picker"
    v-bind="$attrs"
  >
    <template v-slot="{ inputValue, togglePopover }">
      <CInput
        :label="label"
        :value="inputValue"
        :disabled="disabled"
        :readonly="readonly"
        :isValid="isValid"
        :invalidFeedback="invalidFeedback"
        @click="!disabled ? togglePopover() : null"
      >
        <template #append-content v-if="clearButton">
          <span @click="onClear()">
            <CIcon name="cil-trash" />
          </span>
        </template>

        <template #label>
          <slot name="label"></slot>
        </template>
      </CInput>
    </template>
  </v-date-picker>
</template>

<script>
import { DatePicker } from "v-calendar";
export default {
  name: "UiDatePicker",
  components: {
    "v-date-picker": DatePicker,
  },
  props: {
    date: {
      type: [String, Date],
      default: function () {
        new Date();
      },
    },
    label: { type: String, default: "" },
    isValid: { type: Boolean, default: undefined },
    invalidFeedback: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    clearButton: { type: Boolean, default: true },
    readonly: { type: Boolean, default: false },
  },

  data() {
    return {
      dateValue: this.date,
    };
  },

  watch: {
    dateValue: function (newValue) {
      this.$emit("update:date", newValue);
    },
  },

  methods: {
    onClear() {
      this.dateValue = null;
      this.$emit("onClear", this.dateValue);
    },
  },
};
</script>

<style scoped>
.ui-date-picker >>> .form-control[readonly] {
  background-color: #fff !important;
}
</style>
